<template>
  <b-container>
    <b-row>
      <b-col>
        <app-fotos />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import fotos from "@/components/Fotos.vue";

export default {
  name: "Fotos",
  components: {
    appFotos: fotos,
  },
};
</script>
