<template>
  <div>
    <b-card class="mt-3">
      <app-head />

      <b-card title="Fast geschafft!">
        <b-row>
          <b-col>
            Lade bitte Fotos von Deinem gereinigten und ungeschminkten Gesicht
            hoch. Die Fotos sollten bitte gut ausgeleuchtet sein und mit der
            besten verfügbaren Kamera aufgenommen werden (zum Beispiel verwende
            Deine Smartphonekamera vor einem Spiegel). Deine Haut und deine
            Poren sollten möglichst gut zu erkennen sein!
          </b-col>
        </b-row>
      </b-card>
      <b-card class="mt-1">
        <b-row class="my-2">
          <b-col sm="12" md="3" class="text-left">Linke Seite</b-col>
          <b-col sm="12" md="9">
            <b-form-file
              v-model="bildLinks"
              accept="image/jpeg, image/png, image/gif"
              placeholder="Datei auswählen oder via 'Drag and Drop' hier reinziehen"
              drop-placeholder="Dateien hier reinziehen..."
              ref="file-input"
            >
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
              </template>
            </b-form-file>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col sm="12" md="3" class="text-left">Rechte Seite</b-col>
          <b-col sm="12" md="9">
            <b-form-file
              v-model="bildRechts"
              accept="image/jpeg, image/png, image/gif"
              placeholder="Datei auswählen oder via 'Drag and Drop' hier reinziehen"
              drop-placeholder="Dateien hier reinziehen..."
              ref="file-input"
            >
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
              </template>
            </b-form-file>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col sm="12" md="3" class="text-left">Vorderseite</b-col>
          <b-col sm="12" md="9">
            <b-form-file
              v-model="bildVorderseite"
              accept="image/jpeg, image/png, image/gif"
              placeholder="Datei auswählen oder via 'Drag and Drop' hier reinziehen"
              drop-placeholder="Dateien hier reinziehen..."
              ref="file-input"
            >
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
              </template>
            </b-form-file>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-input-group>
              <template v-slot:append>
                <b-input-group-text v-if="saveAnimation">
                  <b-spinner
                    class="spinnerSize"
                    variant="dark"
                    label="Lade..."
                  ></b-spinner>
                </b-input-group-text>
                <b-input-group-text
                  v-if="saveAnimation_Finish"
                  :style="{ color: 'green' }"
                >
                  <v-icon name="check-circle" scale="1" />
                </b-input-group-text>
              </template>
              <b-button @click="uploadImg" variant="outline-success"
                >Bilder hochladen</b-button
              >
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-alert
              :show="dismissCountDown"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown = 0"
              @dismiss-count-down="countDownChanged"
              class="mt-2"
            >
              <p>Fehler - Bitte lade alle Fotos gleichzeitig hoch.</p>
            </b-alert>
          </b-col>
        </b-row>
      </b-card>
      <b-row class="mt-3">
        <b-col cols="6"
          ><b-button @click="zurLetztenFrage" variant="outline-dark"
            ><v-icon name="arrow-left" scale="1" /></b-button
        ></b-col>
        <b-col cols="6"
          ><b-button @click="zumAbschluss" variant="outline-dark"
            ><v-icon name="arrow-right" scale="1" /></b-button
        ></b-col>
      </b-row>
    </b-card>
    <b-card v-if="allImgUploaded" class="mt-3" style="margin-bottom: 100px">
      <b-row>
        <b-col lg="4" md="12">
          Linke Seite
          <b-img thumbnail fluid :src="getBildLinks" alt="Image 1"></b-img>
        </b-col>
        <b-col lg="4" md="12">
          Rechte Seite
          <b-img thumbnail fluid :src="getBildRechts" alt="Image 1"></b-img>
        </b-col>
        <b-col lg="4" md="12">
          Vorderseite
          <b-img
            thumbnail
            fluid
            :src="getBildVorderseite"
            alt="Image 1"
          ></b-img>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import Head from "@/components/Head.vue";

export default {
  data() {
    return {
      bildLinks: null,
      bildRechts: null,
      bildVorderseite: null,
      saveAnimation: false,
      saveAnimation_Finish: false,
      dismissCountDown: 0,
    };
  },
  components: {
    appHead: Head,
  },
  watch: {
    allImgUploaded(n) {
      if (n) {
        this.saveAnimation = false;
        this.saveAnimation_Finish = true;
        this.bildLinks = null;
        this.bildRechts = null;
        this.bildVorderseite = null;
        setTimeout(() => {
          this.saveAnimation_Finish = false;
        }, 1000);
      }
    },
  },
  props: {
    msg: String,
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },

    uploadImg() {
      if (this.bildLinks && this.bildRechts && this.bildVorderseite) {
        this.saveAnimation = true;
        this.upload(this.bildLinks, "setBildLinks");
        this.upload(this.bildRechts, "setBildRechts");
        this.upload(this.bildVorderseite, "setBildVorderseite");
      } else {
        this.dismissCountDown = 5;
      }
    },
    upload(datei, commitValue) {
      let formData = new FormData();
      formData.append("api_key", process.env.VUE_APP_APIKEY);
      formData.append("file", datei);
      formData.append("upload_preset", process.env.VUE_APP_upload_preset);

      axios
        .post(process.env.VUE_APP_UploadURL, formData)
        .then((result) => {
          let url = result.data.url;
          if (url !== "") {
            this.$store.commit(commitValue, url);
          }
        })
        .catch(() => {
          this.$bvToast.toast("Bild konnte nicht gespeichert werden!", {
            title: "Fehler",
            autoHideDelay: 5000,
          });
        });
    },
    zurLetztenFrage() {
      const currentUser = this.$store.getters.getCurrentUser;
      this.$router.replace({
        path:
          "/" +
          currentUser.MitarbeiterLink +
          "/Fragen/" +
          process.env.VUE_APP_FragenAnzahl,
      });
      this.$store.commit("setRvKey", Math.random());
    },
    zumAbschluss() {
      if (this.allImgUploaded) {
        const currentUser = this.$store.getters.getCurrentUser;
        this.$router.replace({
          path: "/" + currentUser.MitarbeiterLink + "/Abschluss",
        });
        this.$store.commit("setRvKey", Math.random());
      } else {
        this.$bvToast.toast(
          "Bitte lade die geforderten Bilder hoch. Wir benötigen diese, um eine entsprechende Beratung vornehmen zu können.",
          {
            title: "Achtung",
            autoHideDelay: 5000,
          }
        );
      }
    },
  },
  computed: {
    allImgUploaded() {
      return (
        this.getBildLinks !== "" &&
        this.getBildRechts !== "" &&
        this.getBildVorderseite !== ""
      );
    },
    getBildLinks() {
      return this.$store.getters.getBildLinks;
    },
    getBildRechts() {
      return this.$store.getters.getBildRechts;
    },
    getBildVorderseite() {
      return this.$store.getters.getBildVorderseite;
    },
  },
};
</script>
